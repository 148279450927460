import { config } from '../config';
import { animate, animate_delay } from '../helpers/animate';
import { debug } from '../helpers/debug';

// Affiche une pub en fonction d'une action réalisée par l'utilisateur
const actions = {
  // Ajoute dynamiquement un slot de pub
  add_slot: action => {
    // if (config.connection != '4g') return;
    const { cible, name, number, formats, path } = config.actions[action].slot;

    // Nom du slot
    let slotName = name + number;
    config.actions[action].slot.number++;
    debug('tag', 'add slot', slotName);

    // Création de la div pour le slot
    let slotDiv = document.createElement('div');
    slotDiv.id = slotName; // Id must be the same as slotName
    document.querySelector(cible).appendChild(slotDiv);

    // Define the slot itself, call display() to
    // register the div and refresh() to fetch ad.
    googletag.cmd.push(function() {
      let slot = googletag.defineSlot(path, formats, slotName).
          // setTargeting("test","infinitescroll").
          addService(googletag.pubads());

      // Display has to be called before
      // refresh and after the slot div is in the page.
      googletag.display(slotName);
      // googletag.pubads().refresh([slot]);
    });
  },

  load_slot: action => {
    const { cible, name, number } = config.actions[action].slot;

    let slotname = (number > 1) ? name + (number - 1) : name + 1;
    debug('tag', 'slotname', slotname, config.ads[slotname]);

    // document.querySelector(cible).innerHTML = '';

    if (config.ads[slotname] && config.ads[slotname]._viewable === false) {
      debug('tag', 'waiting for viewabality');
    } else {
      actions.add_slot(action);
    }
  },

  refresh_slot: action => {
    const { name } = config.actions[action].slot;
    debug('tag', 'refresh slot', name, config.actions[action].slot);

    googletag.cmd.push(function() {
      googletag.pubads().refresh([window[name]]);
    });
  },

  // Récupère une pub
  get_ad: options => {
    // config.ads
    let slot = false; // config._load[0]
    let mode = options.mode || 'sticky';
    let position = (Array.isArray(options.position)) ? options.position[config._window.breakpoint] : options.position || 'bottom';

    config._load.forEach(id => {
      let {[id]:ad} = config.ads;
      if (!ad._viewable && !ad._visible) slot = id;
    });

    if (slot) {
      let {[slot]:ad} = config.ads;
      debug('tag', 'get_ad', mode, position);
      // TODO: créer positionnement auto en fonction de la taille de la pub
      animate(slot, ad.element, false, { mode: mode, position: position });
      animate_delay(slot, ad.element, false, { mode: mode, position: position });

    } else {
      debug('alert', 'get_ad', 'Pas de pub ou toutes les pubs ont été vues');
    }
  }
};

export { actions };
