import { config } from '../config';
import { css } from '../helpers/css';
import { debug } from '../helpers/debug';

// Mode de fixation de la pub
const speedbump = {
  init: () => {
    let css = `
      @-webkit-keyframes speedbump {
        to { overflow: auto; touch-action: auto }
      }
      @keyframes speedbump {
        to { overflow: auto; touch-action: auto }
      }
      .speedbump {
        animation: 1s linear speedbump forwards;
      }`;
    let head = document.head || document.getElementsByTagName('head')[0];
    let style = document.createElement('style');

    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
  },

  // active le sticky sur une pub
  set: id => {
    const { breakpoint } = config.mobile;
    const { page } = config._window;

    if (window.matchMedia(config.breakpoints[breakpoint]).matches) {
      debug('tag', 'set speedbump', config.ads[id]);
      //
      css(page, {
        overflow: 'hidden',
        touchAction: 'none'
      });
      page.classList.add('speedbump');
      // location.hash = "#" + id;

      setTimeout(() => {
        speedbump.unset();
      }, 900);
    }
  },

  unset: () => {
    const { page } = config._window;

    css(page, {
      overflow: null,
      touchAction: null
    });
    page.classList.remove('speedbump');

    debug('tag', 'unset speedbump');
  }
}

export { speedbump };
