import { config } from '../config';
import { debug } from '../helpers/debug';

// Envoie un event analytics
const analytics = (action, label) => {
  if (config.analytics.active) {
    // _gaq.push(['_trackEvent', 'viewtiful', action, label]);
    ga(config.analytics.send, config.analytics.event, config.analytics.category, action, label);
    // console.log(config.analytics.send, config.analytics.event, config.analytics.category, action, label);
    debug('tag', 'analytics', action, label);
  }
}

export { analytics };
