// Polyfill intersection observer
// import './vendors/intersection-observer';

// config
import { config } from './config';

// helpers
import { get_browser } from './helpers/browser';
import { debug } from './helpers/debug';
import { deepmerge } from './helpers/deepmerge';
import { breakpoint } from './helpers/breakpoint';

// events
// import { change as event_change } from './events/change';
import { resize as event_resize } from './events/resize';
import { visibility as event_visibility } from './events/visibility';
import { mouseleave as event_mouseleave } from './events/mouseleave';
import { scroll as event_scroll } from './events/scroll';
import { click as event_click } from './events/click';
import { google } from './events/googletag';

// components
import { actions } from './mode/action';
import { speedbump } from './mode/speedbump';


// PUBLIC
// TODO: swap onload
// TODO: kill mouseover après une fois
const viewtiful = {
  start: false,

  // Initialisation de viewtiful
  init: perso => {
    // Test si le script est déjà initialisé
    if (window.viewtiful.start) return;

    // Si le navigateur n'est pas compatible avec les intersectionObserver
    if ('IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
      // Minimal polyfill for Edge 15's lack of `isIntersecting`
      // See: https://github.com/w3c/IntersectionObserver/issues/211
      if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
        Object.defineProperty(window.IntersectionObserverEntry.prototype,
          'isIntersecting', {
          get: function () {
            return this.intersectionRatio > 0;
          }
        });
      }
    } else return;

    // BROWSER
    // Détection du navigateur
    // Si le navigateur est dans la liste des exclusions, on stoppe l'initialisation
    config._browser = get_browser();
    const { exclude, minimal } = config.browsers;

    // Si le navigateur est dans la liste des minimum
    minimal.forEach(browser => {
      if (browser.indexOf(config._browser[0]) !== -1 && browser < config._browser[1]) {
        debug('warning', 'Disable', 'Navigateur incompatible', '', '', 1);
        return;
      }
    });

    // Si le navigateur est exclu, on arrête
    if (exclude.indexOf(config._browser[0]) !== -1) {
      debug('warning', 'Disable', 'Navigateur exclu', '', '', 1);
      return;
    }

    // Si l'objet googletag n'est pas défini on stoppe la librairie
    if (!window.googletag) {
      debug('warning', 'Googletag', 'Objet googletag n\'existe pas', '', '', 1);
      return;
    }

    // DEBUG
    let url_string = window.location.href;
    let url = new URL(url_string);

    //config._debug.active = true;
    if (url.searchParams && url.searchParams.get('viewtiful')) {
      config._debug.active = true;
    }

    // Browser
    debug('data', 'Browser', config._browser);

    // INIT
    // Initialisation config viewtiful
    deepmerge.merge(config, perso);
    config._window.breakpoint = breakpoint();
    debug('data', 'config', config);

    // Speedbump
    speedbump.init();

    // EVENTS
    // event_change();
    event_resize();
    if (config.onvisibility) event_visibility();
    if (config.onleave) event_mouseleave();
    event_scroll();

    window.addEventListener('DOMContentLoaded', function () {
      event_click();
    });

    // Google Tag
    // Suivi des events de Google
    googletag.cmd.push(function() {
      // Le slot envoie une requête à Google
      // googletag.pubads().addEventListener('slotRequestedEvent', function (e) {
      //   if (e.isEmpty) return;

      //   let slotID = e.slot.getSlotElementId();
      //   google.slotRequestedEvent(slotID);
      // });

      // Le slot reçoit une réponse de Google
      googletag.pubads().addEventListener('slotResponseReceived', function (e) {
        if (e.isEmpty) return;

        let slotID = e.slot.getSlotElementId();
        google.slotResponseReceived(slotID);
      });

      // La fonction google display a été lancée
      googletag.pubads().addEventListener('slotRenderEnded', function (e) {
        if (e.isEmpty) return;

        let slotID = e.slot.getSlotElementId();
        google.slotRenderEnded(slotID, e);
      });

      // La pub dans l'iframe a fini de charger (!!! iframe obligatoire pour l'event !!!)
      googletag.pubads().addEventListener('slotOnload', function (e) {
        if (e.isEmpty) return;

        let slotID = e.slot.getSlotElementId();
        google.slotOnload(slotID);
      });

      // L'impression est comptabilisée
      googletag.pubads().addEventListener('impressionViewable', function (e) {
        if (e.isEmpty) return;

        let slotID = e.slot.getSlotElementId();
        google.impressionViewable(slotID);
      });
    });

    window.viewtiful.start = true;
  },

  // Function pour un appel de l'extérieur
  action: name => {
    debug('info', 'action', name);
    if (!config.actions[name] || !config.actions[name].mode) return;

    let mode = config.actions[name].mode;
    switch (mode) {
      case 'add':
        actions.load_slot(name);
        break;
      case 'refresh':
          actions.refresh_slot(name);
          break;
      default:
        actions.get_ad(config.actions[name]);
        break;
    }
  },

  // Function pour un retrait de l'extérieur
  remove: name => {
    debug('info', 'action remove', name);
  },

  debug: () => {
    config._debug.log.forEach(log => {
      debug(log[0], log[1], log[2], log[3], log[4], 1);
    });

    config._debug.active = true;
  }
};

if (typeof window.viewtiful === 'undefined') {
  window.viewtiful = viewtiful;
  window.viewtiful_config && viewtiful.init(viewtiful_config);
}
