import { config } from '../config';
import { debug } from '../helpers/debug';
import { speedbump } from '../mode/speedbump';
import { swap } from '../mode/swap';
import { sticky } from '../mode/sticky';
import { preload } from '../mode/preload';

// Création intersection observer
const observer = new IntersectionObserver(slots => {
  slots.forEach(slot => {
    let id = slot.target.id;
    const {[id]:ad} = config.ads;
    debug('tag', 'ratio', slot.intersectionRatio);

    if (slot.intersectionRatio > 0) {
      debug('event', id, 'in the view');

      // la pub revient à sa position initiale
      if ('parent-' + config._stuck == id) {
        debug('tag', 'position initiales', id);
        // on tue tous les setTimeout
        config.ads[config._stuck].timeout.forEach(timeout => {
          clearTimeout(timeout);
        });

        sticky.set_unfixed(config._stuck, 0, { transition: 'none' });
      }

      if (ad) {
        // Indique que la pub est visible
        ad._visible = false;
        // Indique que la pub a été visible au moins une fois pour le onposition
        ad._swapable = true;

        // Au-dessus de 90% on considère que la pub est complètement visible
        if (slot.intersectionRatio > 0.7) {
          // Indique que la pub est visible
          ad._visible = true;

          if (!ad._viewable && ad.speedbump) {
            speedbump.set(id);
          }

        } else {
          ad._visible = false;
        }
      }

      // Si le bloc fantôme revient dans le champs visuel, on le swap avec sa pub
      if (slot.target.dataset.viewtiful && slot.target.dataset.viewtiful !== 'disable') {
        debug('tag', slot.target.dataset.viewtiful, 'deplace ' + id);
        swap.deplace_ghost(id, slot.target.dataset.viewtiful);
      }

    } else {
      debug('event', id, 'out of view');

      if (ad) {
        // Indique que la pub n'est pas visible
        ad._visible = false;

        if (ad.preload && ad._status == 'slotResponseReceived') {
          preload.set(id);
        }

        if (ad.mode === 'swap') {
          let mode = ad.swap || config.sticky.mode;

          switch (mode) {
            case 'onposition':
              if (ad._swapable) {
                swap.deplace_ad(id);
              }
            break;

            case 'onload':
              swap.deplace_ad(id);
            break;
          }
        }
      }
    }
  });
}, {
  // rootMargin: -config.offset.top + 'px 0px 0px 0px',
  threshold: [0, .75, 1]
});

export { observer };
