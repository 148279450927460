import { config } from '../config';
// import { debug } from '../helpers/debug';

// applique une série de configuration à une pub via un nom de profil
const set_profile = id => {
  let {[id]:ad} = config.ads;
  let props = Object.assign({}, config.profile[ad.profile]);

  if (props) {
    config.ads[id] = Object.assign(props, ad);
    //debug('tag', 'set profile', config.ads[id]);
  }
}

export { set_profile };
