import { config } from '../config';
import { debug } from '../helpers/debug';
import { actions } from '../mode/action';

// Visibility event
const visibility = () => {
  // Set the name of the hidden property and the change event for visibility
  var hidden, visibilityChange;
  if (typeof document.hidden !== undefined) { // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.mozHidden !== undefined) {
    hidden = 'mozHidden';
    visibilityChange = 'mozvisibilitychange';
  } else if (typeof document.msHidden !== undefined) {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== undefined) {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  // Handle page visibility change
  window.addEventListener(visibilityChange, function () {
    if (document[hidden]) {
      debug('event', 'visibility', 'hidden');

    } else {
      debug('event', 'visibility', 'visible');
      actions.get_ad(config.onvisibility);
    }
  }, false);
};

export { visibility };
