import { config } from '../config';
import { css } from '../helpers/css';
import { debug } from '../helpers/debug'

const preload = {
  set: id => {
    debug('tag', id, 'set preload');
    const { element } = config.ads[id];

    // On donne la hauteur de la pub au parent pour empêcher les décalages quand elle sera sticky
    // css(element.parentNode, {
    //   height: element.offsetHeight + 'px'
    // });

    css(element, {
      bottom: '100%',
      padding: '0',
      position: 'fixed'
    });
  },

  unset: id => {
    debug('tag', id, 'unset preload');
    const { element } = config.ads[id];

    css(element, {
      bottom: null,
      padding: null,
      position: null
    });
  }
}

export { preload }
