import { config } from '../config';
import { debug } from '../helpers/debug';

// Vérifie si la surface de la pub est plus petite que 30% de l'écran
const check_surface = id => {
  const { size:ad } = config.ads[id];
  const { width, height, surface } = config._window;

  // si on a ajouté une hauteur ou largeur max à la pub
  if (ad.height > config.ads[id].maxheight || ad.width > config.ads[id].maxwidth) return false;
  // si trustAds n'est pas actif on ne contrôle pas
  if (!config.trustAds) return true;

  let ratio;
  let check;

  // si la pub est sticky sur toute la largeur ou toute la hauteur, on en tiens compte dans le calcul
  switch (ad.position) {
    case 'bottom':
    case 'top':
      ratio = ((width * ad.height) / surface) * 100;
    break;

    case 'left':
    case 'right':
      ratio = ((height * ad.width) / surface) * 100;
    break;

    default:
      ratio = (ad.surface / surface) * 100;
    break;
  }

  check = (ratio < 30) ? true : false;

  if (!check) debug('warning', 'Surface > 30%', 'Surface supérieure à 30% de l\'écran. Sticky désactivé');

  return check;
}

export { check_surface };
