import { config } from '../config';
import { breakpoint } from '../helpers/breakpoint';
import { debug } from '../helpers/debug';

// Resize event
const resize = () => {
  // Event au redimensionnement du navigateur
  let timer;
  window.addEventListener('resize', function () {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      config._window.breakpoint = breakpoint();
      debug('event', 'breakpoint', config.breakpoints[config._window.breakpoint]);
    }, 500);
  });
}

export { resize };
