import { config } from '../config';
import { debug } from '../helpers/debug';
import { actions } from '../mode/action';

// Mouseleave event
const mouseleave = () => {
  document.documentElement.addEventListener('mouseleave', function (e) {
    // Si la souris quitte l'écran par le haut
    if (e.clientY <= 0) {
      debug('event', 'mouseleave', 'souris hors du viewport');
      let delay = config.onleave.delay || 750;

      // debug('event', 'mouseleave', e);
      config._onleave = setTimeout(() => {
        actions.get_ad(config.onleave);
      }, delay);
    }
  });

  document.documentElement.addEventListener('mouseenter', function (e) {
    // Si la souris reviens à l'écran avant le délai
    clearTimeout(config._onleave);
  });
};

export { mouseleave };
