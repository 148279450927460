// Récupère l'offset d'un élément par rapport à la page
const offset = element => {
  let rect = element.getBoundingClientRect();
  return {
    bottom: rect.bottom,
    left: rect.left,
    right: rect.right,
    top: rect.top + window.pageYOffset
  };
}

export { offset };
