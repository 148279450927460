import { config } from '../config';
import { analytics } from '../helpers/analytics';
import { debug } from '../helpers/debug';
import { observer } from '../events/observer';
import { offset } from '../helpers/offset';
import { set_profile } from '../helpers/profile';
import { css } from '../helpers/css';
import { slider } from '../mode/slider';
import { sticky } from '../mode/sticky';
import { swap } from '../mode/swap';
import { preload } from '../mode/preload';

// Google Tag event load
const google = {
  // slotRequestedEvent: id => {
  //   analytics('slotRequestedEvent', id, 'request');
  //   debug('info', 'slotRequestedEvent', id);
  // },

  slotResponseReceived: id => {
    debug('info', 'slotResponseReceived', id);

    // S'il n'y a pas de config existante
    if (!config.ads[id]) config.ads[id] = {};

    // On initialise un maximum de propriété de la pub
    config.ads[id].profile = (config.ads[id] || config.default.profile).profile || config.default.profile;
    set_profile(id);

    const {[id]:ad} = config.ads; // on ne le met qu'après le set_profile pour la prise en compte des profils

    ad._status = 'slotResponseReceived';
    ad.ghost = 0;
    ad.hover = false;
    ad.timeout = [];
    ad._viewable = false;
    ad._swapable = false;

    // On fait les query au chargement de la pub pour ne les faire qu'une fois
    ad.element = document.querySelector('#' + id);
    ad.parent = (ad.element) ? document.querySelector('#' + id).parentNode : null;
    ad.zone = (config.swap.zones) ? document.querySelector('#' + id).closest(config.swap.zones) : null;

    config.ads[id] = ad; // écriture dans la config principale

    if (ad.parent) {
      css(ad.parent, { minHeight: ad.parent.offsetHeight + 'px' });
    }

    if (ad.element && ad.mode == 'sticky') {
      observer.observe(ad.element);
    }

    analytics(id, 'slotResponseReceived');
  },

  slotRenderEnded: (id, e) => {
    const {[id]:ad} = config.ads; // on ne le met qu'après le set_profile pour la prise en compte des profils

    ad._status = 'slotRenderEnded';
    ad.size = {
      width: e.size[0],
      height: e.size[1],
      surface: e.size[0] * e.size[1]
    };

    if (ad.mode === 'exclude' || ad.element.dataset.viewtiful === 'disable') {
      debug('warning', 'Exclude', id);
    } else {
      config._load.push(id);
    }

    // Si on est en mobile, on force le swap pour le format plus grand que la hauteur max autorisée
    const { maxheight, breakpoint } = config.mobile;
    if (window.matchMedia(config.breakpoints[breakpoint]).matches && ad.size.height > maxheight) {
      ad.mode = 'swap';
    }

    config.ads[id] = ad; // écriture dans la config principale

    //mode preload
    if (ad.preload) {
      preload.unset(id);
    }

    // Mode slider
    if (ad.mode == 'slider') {
      slider.set(id);
    }

    analytics(id, 'slotRenderEnded');
    debug('info', 'slotRenderEnded', id, e);
  },

  // Google Tag event iframe load
  slotOnload: id => {
    const {[id]:ad} = config.ads;
    if (!ad || !ad.element) return;

    ad._status = 'slotOnload';

    // Si la pub est en mode swap, on initialise le swap
    if (ad.mode == 'swap' && ad.zone !== null) {
      swap.init(id);
      ad._swapable = false;
    }

    // En mode sticky, on suit le parent pour annuler les styles au retour sur la position
    if (ad.mode == 'sticky') {
      ad.parent.id = 'parent-' + id;
      config.sticky.parent.push('parent-' + id);
      observer.observe(ad.parent);
    } else {
      // suivre les pubs non sticky
      observer.observe(ad.element);
    }

    debug('alert', 'slotOnload', id, 'mode: ' + ad.mode);

    // On enregistre la distance des pubs par rapport au haut de la page
    // On boucle pour raffraichir la hauteur des pubs chargées plus tard
    config._load.forEach(slot => {
      config.ads[slot].offset = offset(config.ads[slot].element).top;
    });

    config.ads[id] = ad;
  },

  // Google Tag event impression
  impressionViewable: id => {
    const {[id]:ad} = config.ads;

    // La pub est considérée comme vue par Google
    ad._viewable = true;
    ad._status = 'impressionViewable';

    // Défini de quelle façon la pub a été vue
    let value;
    if (config._stuck == id) {
      value = 'sticky';
    } else if (ad.ghost > 0) {
      value = 'swap';
    } else {
      value = 'default';
    }

    if (ad.mode == 'slider' && ad.unset) {
      slider.unset(id);
    }

    analytics(id, 'impressionViewable.' + value);
    debug('success', 'impressionViewable', id);
    debug('data', 'Ad #' + id, ad);

    // Si on survol la pub, on ne la cache pas
    if (!ad.hover && config._stuck && ad.mode == 'sticky') sticky.set_unfixed(id);

    // On stoppe l'intersection observer
    // if (ad.mode === 'swap' && ad.zone !== null) observer.unobserve(ad.element);
    if (!config._debug.active) {
      observer.unobserve(ad.element);
      if (ad.mode == 'sticky') observer.unobserve(ad.parent);
    }

    config.ads[id] = ad;
  }
}

export { google };
