import { config } from '../config';
import { css } from '../helpers/css';
import { debug } from '../helpers/debug';
import { offset } from '../helpers/offset';
import { observer } from '../events/observer';

// Mode de déplacement de la pub hors du champs de vision de l'utilisateur
const swap = {
  // initialise le swap dans une zone
  init: id => {
    const {[id]:ad} = config.ads;

    // S'il n'y a pas de zone de swap on ne poursuit pas
    if (ad.zone !== null) {
      css(ad.zone, {
        display: 'flex',
        flexDirection: 'column'
      });

      swap.reorder(id);

      debug('tag', id, 'initialisation du swap');
    }
  },

  // Ajoute un bloc fantôme
  add_ghost: id => {
    const {[id]:ad} = config.ads;
    let ghost = document.createElement('div');

    ad.ghost++;
    ghost.id = 'ghost-' + ad.ghost + '-' + id;
    ghost.style.height = ad.size.height + 'px';
    ghost.dataset.viewtiful = id;
    ghost.className = config.swap.class;
    if ((ad.swap || false).class) ghost.className += ' ' + ad.swap.class;
    css(ghost, config.swap.style);
    if (ad.swap) css(ghost, ad.swap);
    ad.zone.appendChild(ghost);
    observer.observe(ghost);

    debug('tag', id, 'ajout bloc fantôme ' + ghost.id);

    return ghost;
  },

  // Récupère le bloc près duquel il faut déplacer la pub
  get_position: id => {
    const {[id]:ad} = config.ads;

    let ads_parent =  ad.parent;
    let zone = ad.zone;
    let children = zone.children;
    let offset_page = window.pageYOffset;
    let offset_zone = offset(ad.zone).top;
    let count_height = false;
    let swap_first = false;
    let swap_height = 0;
    let swap_child = null;
    let blocks = config.swap._blocks;

    // Si la zone est plus basse que le champs de visibilité
    if (offset_zone > offset_page + config._window.height) {
      swap_child = zone.children[0];
      debug('tag', id, 'zone hors du champs de visibilité');

    // Si la zone est plus haute que le champs de visibilité
    } else if (offset_zone + zone.offsetHeight < offset_page) {
      swap_child = zone.children[zone.children.length - 1];
      debug('tag', id, 'zone hors du champs de visibilité');

    } else {
      // Si l'utilisateur scroll vers le haut, on inverse l'ordre des blocs pour le calcul de la hauteur
      // if (config._scroll.direction == 'top') {
      //   blocks.reverse();
      // }

      // Calcul le nombre de bloc nécessaire pour faire le swap de manière invisible
      for (let i = 0; i < blocks.length; i++) {
        // On additionne la hauteur des blocs suivant la pub jusqu'au dépassement du viewport
        if (count_height && !swap_first) {
          // Si la hauteur des blocs est plus grande que la hauteur du viewport
          if (swap_height < config._window.height) {
            swap_height += children[blocks[i][0]].offsetHeight;

          } else {
            swap_child = children[blocks[i][0]];
            swap_first = true;
          }
        }

        // A la rencontre de la pub courante on pourra commencer à calculer le nombre de blocs nécessaire pour le swap
        if (children[blocks[i][0]] === ads_parent) {
          count_height = true;
        }
      }

      if (!swap_child) swap_child = zone.children[zone.children.length - 1];
    }

    return swap_child;
  },

  // Renumérote les order de chaque bloc afin qu'il soit unique
  reorder: id => {
    const {[id]:ad} = config.ads;

    let blocks = [];
    let children = ad.zone.children;

    for (let i = 0; i < children.length; i++) {
      blocks[i] = [i, children[i].style.order];
    }

    blocks.sort((a, b) => {
      return a[1] - b[1];
    });

    config.swap._blocks = blocks;

    // liste tous les blocs de la zone et leur réattribue un ordre
    for (let i = 0; i < blocks.length; i++) {
      // réattribution de l'ordre
      children[blocks[i][0]].style.order = i + 1;
    }
  },

  // Change la position de la pub en dehors de la zone visible
  deplace_ad: id => {
    const {[id]:ad} = config.ads;

    // S'il n'y a pas de zone de swap ou si le nombre de swap maximum est atteint on ne poursuit pas
    if (ad.zone === null || ad.ghost >= config.swap.max) return;

    let ads_parent =  ad.parent;
    let swap_element = swap.add_ghost(id);
    let swap_child = swap.get_position(id);

    swap.reorder(id);

    // On échange les valeurs des ordres entre la pub et le bloc fantôme
    css(swap_element, {
      order: ads_parent.style.order
    });
    css(ads_parent, {
      order: swap_child.style.order
    });
  },

  // Déplace le bloc fantôme en dehors de la zone visible
  // TODO: placer la pub originale vraiment à la place du ghost
  deplace_ghost: (ghost, id) => {
    const {[id]:ad} = config.ads;

    // S'il n'y a pas de zone de swap on ne poursuit pas
    if (ad.zone === null) return;

    let ads_parent =  ad.parent;
    let swap_element = document.querySelector('#' + ghost);

    swap.reorder(id);

    // On échange les valeurs des order entre la pub et le bloc fantôme
    let swap_order = swap_element.style.order; // évite l'écrasement mutuel des order
    css(swap_element, {
      order: ads_parent.style.order
    });
    css(ads_parent, {
      order: swap_order
    });

    // Si la pub est vue, on retire le bloc fantôme
    debug('tag', id, 'swap back - remove ' + ghost);
    swap_element.parentNode.removeChild(swap_element);
  }
}

export { swap };
