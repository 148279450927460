import { config } from '../config';
import { debug } from '../helpers/debug';

// Click event
const click = () => {
  window.addEventListener('click', function (e) {
    // activer le debug dans la console
    if (config._debug.count >= 6) {
      config._debug.count = 0;

      config._debug.log.forEach(log => {
        debug(log[0], log[1], log[2], log[3], log[4], 1);
      });

      config._debug.active = true;

    } else {
      // debug('tag', 'count', config._debug.count);
      config._debug.count++;
      clearTimeout(config._debug.timeout);
      config._debug.timeout = setTimeout(() => {
        config._debug.count = 0;
      }, 1000);
    }

    if (config.onclick) {
      // Si l'utilisateur ne clique pas sur un lien ou une image avec un lien
      if (e.target.tagName !== 'A' || (e.target.tagName === 'IMG' && e.target.parentElement.tagName !== 'A')) return;

      debug('event', 'click', e);
      actions.get_ad(config.onclick);
    }
  });
}

export { click };
