// Permet de merger 2 objets complexes sur plusieurs niveaux
const deepmerge = {
  isObject: item => {
    return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
  },

  merge: (target, source) => {
    if (deepmerge.isObject(target) && deepmerge.isObject(source)) {
      Object.keys(source).forEach(key => {
        if (deepmerge.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });
          deepmerge.merge(target[key], source[key]);

        } else {
          Object.assign(target, { [key]: source[key] });
        }
      });
    }

    return target;
  }
}

export { deepmerge };
