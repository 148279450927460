// Détecte le navigateur et sa version
const get_browser = () => {
  let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  if (isIE11) return ['MSIE', 11];

  let user_agent = navigator.userAgent;
  let tem;
  let M = user_agent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(user_agent) || [];
    return {
      browser: 'MSIE',
      version: tem[1] || ''
    };
  }

  if (M[1] === 'Chrome') {
    tem = user_agent.match(/\b(OPR|Edge)\/(\d+)/);

    if (tem != null) {
      tem = tem
        .slice(1)
        .join(' ')
        .replace('OPR', 'Opera')
        .split(' ');

      return {
        browser: tem[0],
        version: tem[1] || ''
      };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

  if ((tem = user_agent.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }

  return [M[0], M[1]];
};

export { get_browser };
