import { config } from '../config';
// import { debug } from '../helpers/debug';
import { scroll_direction } from '../helpers/scrolldirection';
import { sticky } from '../mode/sticky';

// Actions au scroll
const on_scroll = () => {
  var scroll_top = window.scrollY + config.offset.top[config._window.breakpoint];
  config._window.scroll_top = scroll_top;
  scroll_direction(window.scrollY);

  // On vérifie le status de chaque pub chargée dans la page
  config._load.forEach(id => {
    const {[id]:ad} = config.ads;

    if (!ad._viewable && ad.mode == 'sticky') {
      let mode = ad.sticky || config.sticky.mode;

      switch (mode) {
        case 'onposition':
          // si l'utilisateur a dépassé la pub
          if (scroll_top > ad.offset && !config._stuck) {
            sticky.set_fixed(id);
            config._stuck = id;
          }
        break;

        case 'onload':
          if (!config._stuck) {
            sticky.set_fixed(id);
            config._stuck = id;
          }
        break;
      }
    }

    //la pub revient à sa position initiale
    // if (config.sticky.mode == 'onposition' && scroll_top < ad.offset && config._stuck == id) {
    //   debug('tag', 'position initiale', ad.offset, scroll_top);
    //   // on tue tous les setTimeout
    //   ad.timeout.forEach(timeout => {
    //     clearTimeout(timeout);
    //   });

    //   sticky.set_unfixed(id, 0, { transition: 'none' });
    // }
  });
}

// Scroll event
const scroll = () => {
  // Event au scroll dans la page
  let wait = false;
  window.addEventListener('scroll', function () {
    if (!wait) {
      wait = setTimeout(() => {
        on_scroll();
        wait = false;
      }, 100);
    }
  });
};

export { scroll };
