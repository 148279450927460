import { config } from '../config';
import { debug } from '../helpers/debug';

// Retourne le breakpoint courant
const breakpoint = () => {
  for (let i = 0; i < config.breakpoints.length; i++) {
    debug('warning', 'breakpoint', config.breakpoints[i]);
    if (window.matchMedia(config.breakpoints[i]).matches) {
      return i;
    }
  }
}

export { breakpoint };
