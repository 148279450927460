import { config } from '../config';
import { css } from '../helpers/css';
import {debug} from '../helpers/debug';

// Applique une animation
const animate = (id, element, out = false, force = false) => {
  const {[id]:ad} = config.ads;
  const { breakpoint } = config._window;
  const { bottom, left, right, top } = config.offset;

  let style = {};
  let timeout = {};
  let time = 0;
  let mode = (force.mode) ? force.mode : ad.mode;
  let position = (force.position) ? force.position : ad.position;
  let transition = (force.transition) ? force.transition : ad.transition;
  let zIndexReset = {};

  if (mode == 'sticky') {
    if (!out) style.position = 'fixed';
    style.zIndex = 20;
    style.top = null;
    style.bottom = null;
    style.left = null;
    style.right = null;
  }

  switch (position) {
    case 'bottom':
      style.bottom = bottom[breakpoint] + 'px';
    break;

    default:
    case 'top':
      style.top = top[breakpoint] + 'px';
    break;

    case 'left':
      style.left = left[breakpoint] + 'px';
      style.top = top[breakpoint] + 'px'; // position nécessaire
    break;

    case 'right':
      style.right = right[breakpoint] + 'px';
      style.top = top[breakpoint] + 'px'; // position nécessaire
    break;

    case 'bottomleft':
      style.bottom = bottom[breakpoint] + 'px';
      style.left = left[breakpoint] + 'px';
    break;

    case 'bottomright':
      style.bottom = bottom[breakpoint] + 'px';
      style.right = right[breakpoint] + 'px';
    break;

    case 'topleft':
      style.top = top[breakpoint] + 'px';
      style.left = left[breakpoint] + 'px';
    break;

    case 'topright':
      style.top = top[breakpoint] + 'px';
      style.right = right[breakpoint] + 'px';
    break;
  }

  switch (transition) {
    case 'slide':
      style.zIndex = 1000;

      timeout.transform = 'none';
      timeout.transition = 'transform .5s ease';

      time = 500;

      switch (position) {
        case 'top':
          style.left = '0';
          style.right = '0';
          style.top = '0';
          style.transform = 'translateY(-100%)';
        break;

        case 'bottom':
          style.bottom = '0';
          style.left = '0';
          style.right = '0';
          style.transform = 'translateY(100%)';
        break;

        case 'left':
          style.bottom = '0';
          style.left = '0';
          style.top = '0';
          style.transform = 'translateX(-100%)';
        break;

        case 'right':
          style.bottom = '0';
          style.right = '0';
          style.top = '0';
          style.transform = 'translateX(100%)';
        break;

        case 'topleft':
        case 'topright':
          style.transform = 'translateY(-100%)';
        break;

        case 'bottomleft':
        case 'bottomright':
          style.transform = 'translateY(100%)';
        break;
      }
    break;

    default:
    case 'fade':
      style.opacity = 0;

      timeout.opacity = 1;
      timeout.transition = 'opacity .3s ease';

      time = 300;
    break;

    case 'none':
      style.position = 'inherit';
      zIndexReset.zIndex = 'auto'; 
    break;
  }

  if (out) {
    timeout.position = 'static';
    timeout.zIndex = 'auto';
    timeout.transition = 'none'; 
  }

  debug('tag', 'animate', id, out, time, Object.assign(style, (config.sticky || {}).style || {}, (ad.sticky || {}).style || {}));
  let styleValue = Object.assign(style, (config.sticky || {}).style || {}, (ad.sticky || {}).style || {});
  css(element, Object.assign(styleValue, zIndexReset));

  if (time) {
    debug('tag', 'animate timeout', id, out, time, timeout);
    setTimeout(() => {
      css(element, timeout);
    }, time);
  }
}


// Sécurité dans le cas où l'event viewability ne se produit pas
// OU gestion du délai pour une pub afin d'allonger son affichage
const animate_delay = (id, element, wait = false, force = false) => {
  const {[id]:ad} = config.ads;
  let time = (wait !== false) ? wait : config.timeout;
  // debug('tag', 'animate_delay', id, element, force)

  // Conserve le timeout dans un array pour pouvoir l'annuler en cas de hover sur la pub
  ad.timeout.push(setTimeout(() => {
    config._stuck = false;
    if (!wait) config.ads[id]._viewable = true; // si la pub est en timeout on la considère comme vu
    animate(id, element, 1, force);
  }, time));
}

export { animate, animate_delay};
