import { config } from '../config';
import { css } from '../helpers/css';
import { debug } from '../helpers/debug';
import { check_surface } from '../helpers/surface';

// Mode de fixation de la pub
const slider = {
  // active le sticky sur une pub
  set: id => {
    // Si la surface de la pub est trop grande on ne poursuit pas
    if (!check_surface(id)) return;

    const { breakpoint } = config._window;
    const { top } = config.offset;

    const { element, minHeight } = config.ads[id];

    // On donne la hauteur de la pub au parent pour empêcher les décalages quand elle sera sticky
    css(element, {
      position: 'sticky',
      top: top[breakpoint] + 'px'
    });

    css(element.parentNode, {
      minHeight: minHeight + 'px',
      overflow: 'visible',
      transition: 'min-height 1s ease'
    });

    debug('tag', id, 'set slider');
  },

  unset: id => {
    const { element } = config.ads[id];

    css(element.parentNode, {
      minHeight: '0px',
      overflow: null,
      transition: null
    });

    css(element, {
      position: null,
      top: null
    });

    debug('tag', id, 'unset slider');
  }
}

export { slider };
