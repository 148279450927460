import { config } from '../config';
import { animate } from '../helpers/animate';
import { debug } from '../helpers/debug';

// Mouseover event
const mouseover = id => {
  const {[id]:ad} = config.ads;

  // Permet de garder la pub affichée si la souris la survole
  ad.element.addEventListener("mouseover", function mouseover() {
    debug('event', id, 'mouseover');

    ad.timeout.forEach(timeout => {
      clearTimeout(timeout);
    });

    clearTimeout(ad.mouseout);
    ad.hover = true;

    if (!ad.mouseover) mouseout(id);

    // setTimeout(() => {
      // this.removeEventListener('mouseover', mouseover);
    // }, 1000);
  });
};


// Mouseout event
const mouseout = id => {
  const {[id]:ad} = config.ads;
  ad.mouseover = true;

  // Retire la pub quand la souris est en dehors
  ad.element.addEventListener("mouseout", function mouseout() {
    debug('event', id, 'mouseout');

    if (id == config._stuck) {
      ad.mouseout = setTimeout(() => {
        animate(id, ad.element, 1);
        ad.hover = false;
        // this.removeEventListener('mouseout', mouseout);
      }, 500);
    }
  });
};

export { mouseover };
