import { config } from '../config';
// import { debug } from '../helpers/debug';

// Indique dans quel direction scroll l'utilisateur
const scroll_direction = scroll => {
  config._scroll.direction = (config._scroll.last > scroll) ? 'top' : 'bottom';
  config._scroll.last = scroll;
}

export { scroll_direction };
